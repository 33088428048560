import { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const useWaitForGlobal = (name: string): boolean => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const waitForGlobal = () => {
      if (window[name]) {
        setLoaded(true);
      } else {
        timer.current = setTimeout(() => {
          waitForGlobal();
        }, 500);
      }
    };

    waitForGlobal();

    return () => timer.current && clearTimeout(timer.current);
  }, [name]);

  return loaded;
};

export default useWaitForGlobal;
