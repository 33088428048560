import React from 'react';
import { theme } from '@tuunetech/tuune-components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

type CivicCookieCategory = {
  name: string;
  label: string;
  description: string;
  cookies: string[];
  thirdPartyCookies?: { name: string; optOutLink: string }[];
  vendors: {
    name: string;
    description: string;
    url: string;
    thirdPartyCookies?: boolean;
  }[];
  onAccept?: () => void;
  onRevoke?: () => void;
  recommendedState?: boolean;
  lawfulBasis?: string;
};

export enum COOKIE_CATEGORY_NAMES {
  ANALYTICS = 'analytics',
}

const ANALYTICS: CivicCookieCategory = {
  name: COOKIE_CATEGORY_NAMES.ANALYTICS,
  label: 'Analytics',
  description: `We use some cookies to collect information about how people are using our Services.
                  For example, these cookies allow us to know which pages are visited the most often, how people are moving from one link to another and if they get error messages from certain pages. Overall, these cookies provide us with analytical information about how our Services are performing and how we can improve them.`,
  cookies: ['_ga', '_gid', '_gat*'],
  vendors: [
    {
      name: 'Google',
      url: 'https://policies.google.com/technologies/types?hl=en-US',
      description: 'Google Analytics',
    },
  ],
  onAccept: function () {
    window.gtag &&
      window.gtag('consent', 'update', { analytics_storage: 'granted' });
    window.dataLayer &&
      window.dataLayer.push({
        civic_cookies_analytics: 'consent_given',
        event: 'analytics_consent_given',
      });
  },
  onRevoke: function () {
    window.gtag &&
      window.gtag('consent', 'update', { analytics_storage: 'denied' });
    window.dataLayer &&
      window.dataLayer.push({
        civic_cookies_analytics: 'consent_revoked',
        event: 'analytics_consent_revoked',
      });
  },
};

const MARKETING = {
  name: 'marketing',
  label: 'Marketing',
  description: `These show us which products you’ve been looking at so we can show you ads you might like later.`,
  cookies: ['fr'],
  vendors: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/policies/cookies/',
      description: 'Facebook',
    },
  ],
  onAccept: function () {
    window.gtag &&
      window.gtag('consent', 'update', { marketing_storage: 'granted' });
    window.dataLayer.push({
      civic_cookies_marketing: 'consent_given',
      event: 'marketing_consent_given',
    });
  },
  onRevoke: function () {
    window.gtag &&
      window.gtag('consent', 'update', { marketing_storage: 'denied' });
    window.dataLayer.push({
      civic_cookies_marketing: 'consent_revoked',
      event: 'marketing_consent_revoked',
    });
  },
};

const optionalCookies: CivicCookieCategory[] = [];

if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
  // respect do not track option
  if (!(navigator.doNotTrack == '1' || window.doNotTrack == '1')) {
    optionalCookies.push(ANALYTICS);
    optionalCookies.push(MARKETING);
  }
}

export function isConsentGranted(category: string): boolean {
  const categoryIndex = optionalCookies.findIndex(
    element => element.name === category,
  );
  if (!categoryIndex) {
    return false;
  }
  return window.CookieControl.getCategoryConsent(categoryIndex);
}

export const CIVIC_COOKIE_CONFIG = {
  apiKey: process.env.GATSBY_CIVIC_API_KEY,
  product: 'PRO',
  optionalCookies: optionalCookies,
  excludedCountries: 'all',
  mode: 'CCPA',

  statement: {
    description: 'For more information visit our',
    name: 'Privacy Policy',
    url: 'https://www.tuune.com/privacy-policy',
    updated: '14/07/2021',
  },
  ccpaConfig: {
    description:
      'This may include the website or our third-party tools processing personal data. In the case of your personal data, you may opt out of such activity by using the buttons below. To find out more about the categories of personal information collected and the purposes for which such information will be used, please refer to our',
    name: 'Privacy Policy',
    url: 'https://www.tuune.com/privacy-policy',
    updated: '14/07/2021',
  },

  initialState: 'notify',
  position: 'LEFT',
  theme: 'DARK',
  notifyDismissButton: false,
  branding: {
    fontFamily: theme.typography.fontFamily,
    fontColor: theme.palette.common.white,
    notifyFontColor: theme.palette.common.white,
    backgroundColor: theme.palette.common.purple[900],
    alertBackground: theme.palette.common.purple[900],
  },
};

const CIVIC_SCRIPT = (
  <script
    key="civic_source"
    src="https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js"
  />
);

const GOOGLE_TAG_MANAGER_SCRIPT = (
  <script
    key="gtag_script"
    async
    src={`https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GTM_ID}`}
  />
);

const GOOGLE_DATA_LAYER_INIT_SCRIPT = (
  <script key="data_layer_init">
    {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
`}
  </script>
);

export const COOKIE_SCRIPTS = [
  CIVIC_SCRIPT,
  GOOGLE_DATA_LAYER_INIT_SCRIPT,
  GOOGLE_TAG_MANAGER_SCRIPT,
];
