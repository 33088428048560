import useWaitForGlobal from '../hooks/useWaitForGlobal';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AnalyticsProvider from '../analytics';
import { CIVIC_COOKIE_CONFIG, COOKIE_SCRIPTS } from './civicCookie';
import { useFrameMode } from '../hooks/useFrameMode';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const CookieWrapper: React.FC = ({ children }) => {
  const cookieControlLoaded = useWaitForGlobal('CookieControl');
  const [isFrameMode] = useFrameMode();

  useEffect(() => {
    if (cookieControlLoaded && isFrameMode === false) {
      window.CookieControl.load(CIVIC_COOKIE_CONFIG);
    }
  }, [cookieControlLoaded, isFrameMode]);

  return (
    <AnalyticsProvider>
      {isFrameMode === false && <Helmet>{COOKIE_SCRIPTS}</Helmet>}
      {children}
    </AnalyticsProvider>
  );
};

export default CookieWrapper;
