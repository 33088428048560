module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-amplitude-analytics/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"1a679a88d1080eac92e5cf9550a33c8a","head":true,"respectDNT":false,"exclude":[],"eventTypes":{"outboundLinkClick":"CHECKMYPILL_OUTBOUND_LINK_CLICK","pageView":"CHECKMYPILL_LANDING_PAGE_VIEW"},"amplitudeConfig":{"saveEvents":true,"includeFbclid":true,"includeGclid":true,"includeReferrer":true,"includeUtm":true,"sameSiteCookie":"Lax","trackingOptions":{"ip_address":false,"city":false,"dma":false}},"environments":["development","production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
