import React, { ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from '@tuunetech/tuune-components';
import CookieWrapper from './cookies/CookieWrapper';

export const FrameContext = React.createContext<boolean | undefined>(undefined);

export const FrameWrapper: React.FC = ({ children }) => {
  const [isFrameMode, setIsFrameMode] = useState<boolean | undefined>(
    undefined,
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location !== window.parent.location) {
        setIsFrameMode(true);
      } else {
        setIsFrameMode(false);
      }
    }
  }, []);

  return (
    <FrameContext.Provider value={isFrameMode}>
      {children}
    </FrameContext.Provider>
  );
};

export const wrapRootElement: React.FC<{
  element: ReactNode;
}> = ({ element }) => {
  return (
    <FrameWrapper>
      <CookieWrapper>
        <ThemeProvider>{element}</ThemeProvider>
      </CookieWrapper>
    </FrameWrapper>
  );
};
