import { useContext } from 'react';
import { FrameContext } from '../root-wrapper';

export const useFrameMode = (): [
  boolean | undefined,
  (height: number) => void,
] => {
  const isFrameMode = useContext(FrameContext);

  const sendResizeMessage = (height: number) => {
    if (isFrameMode) {
      const message = {
        height,
      };

      if (window?.top) {
        window.top.postMessage(message, '*');
      }
    }
  };

  return [isFrameMode, sendResizeMessage];
};
