import * as React from 'react';
import { useContext, useEffect } from 'react';

type AnalyticsContextType = {
  track: (data: Record<string, unknown>) => void;
};

const defaultValue: AnalyticsContextType = {
  track: () => {
    console.warn(
      'Analytics - Please wrap the root with analytics provider to enable tracking',
    );
  },
};

const AnalyticsContext = React.createContext(defaultValue);

const AnalyticsProvider: React.FC = ({ children }) => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'optimize.activate' });
  }, []);

  const track = (data: Record<string, unknown>) => {
    window.dataLayer.push({ ...data });
  };

  return (
    <AnalyticsContext.Provider value={{ track }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): AnalyticsContextType => {
  const analytics = useContext(AnalyticsContext);

  return analytics;
};

export default AnalyticsProvider;
